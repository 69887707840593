import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import CardColumns from "react-bootstrap/CardColumns"
export const query = graphql`
  query {
    allKeysellerJson(sort: { fields: title }) {
      nodes {
        title
        slug
        description
        image {
          relativePath
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const KeysellerPage = ({ data }) => (
  <Layout pageInfo={{ pageName: "keyseller" }}>
    <SEO title="Keysellerübersicht" />
    <h1>Übersicht über alle Keyseller</h1>
    <CardColumns>
      {data.allKeysellerJson.nodes.map(item => (
        <Card bg="light" style={{ width: "18rem" }}>
          <Card.Img
            variant="top"
            alt="{item.title}"
            src={item.image.childImageSharp.fluid.src}
            style={({ maxHeight: "8rem" }, { height: "8rem" })}
          />
          <Card.Body>
            <Card.Title>{item.title}</Card.Title>
            <Card.Text style={({ maxHeight: "13rem" }, { height: "13rem" })}>
              {item.description.substr(0, 250) + " ..."}
            </Card.Text>
            <Button href={"/keyseller/" + item.slug} variant="primary">
              Mehr erfahren
            </Button>
          </Card.Body>
        </Card>
      ))}
    </CardColumns>
  </Layout>
)

export default KeysellerPage
